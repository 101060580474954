import { getField, updateField } from 'vuex-map-fields'
import { getFormInitState, GlobalFormStore } from '~/mixins/globalForm'

export const talkPreviewFields = {}

const storageKey = 'TalkPreviewForm'

const { gfActions, gfMutations, gfGetters } = GlobalFormStore(storageKey, talkPreviewFields)

// initial fields
export const previewTalkInitState = {
  forms: []
}
export const state = () => {
  return {
    ...previewTalkInitState
  }
}
export const actions = {
  ...gfActions
}
export const mutations = {
  updateField,
  ...gfMutations,
  fill (state, { id, data }) {
    const dirtyJson = localStorage.getItem(`TalkPreviewForm.${id}`)
    const dirty = dirtyJson ? JSON.parse(dirtyJson) : {}
    const fetchedIdx = state.forms.findIndex(i => i.id === id)
    const item = fetchedIdx >= 0
      ? state.forms[fetchedIdx]
      : {
          id,
          dirty,
          oldForm: getFormInitState(talkPreviewFields),
          form: getFormInitState(talkPreviewFields),
          key: 0,
          isFetched: false,
          editColumnName: '',
          editColumnState: false,
          editColumnCursor: -1,
          // Form components refresh keys
          keys: {}
        }
    // Fill form data
    // Copy form data
    Object.keys(item.form)
      .forEach((key) => {
        item.oldForm[key] = item.form[key]
      })
    // Update dirty data
    const dirtyFields = Object.keys(item.dirty)
    if (dirtyFields.length) {
      for (const dirtyField of dirtyFields) {
        item.form[dirtyField] = item.dirty[dirtyField]
      }
    }
    // Store or update data
    if (fetchedIdx >= 0) {
      state.forms[fetchedIdx] = item
    } else {
      state.forms.push(item)
    }
  }
}
export const getters = {
  getField,
  ...gfGetters
}
