import { getField, updateField } from 'vuex-map-fields'
import { uri } from '~/api/uri'

export const initState = {
  postbackURL: null,
  params: null,
  method: 'GET',
  error: null,
  response: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  clearForm ({ commit }) {
    commit('clearForm')
  },
  async send ({ state, commit }) {
    // flush error
    commit('setError', initState.error)
    // send test data
    await this.$axios.$post(uri.affiliateTest, {
      postbackURL: state.postbackURL,
      params: state.params,
      method: state.method
    }).then((res) => {
      commit('setResponce', res)
    }).catch((e) => {
      commit('setError', {
        title: e.message,
        message: e.response && e.response.data && e.response.data.message
      })
    })
  },
  flush ({ commit }) {
    commit('flush')
  }
}
export const mutations = {
  updateField,
  setError (state, value) {
    state.error = value
  },
  setResponce (state, value) {
    state.response = value
  },
  flush (state) {
    state.postbackURL = initState.postbackURL
    state.params = initState.params
    state.method = initState.method
    state.error = initState.error
    state.response = initState.response
  }
}
export const getters = {
  getField
}
