import { getField, updateField } from 'vuex-map-fields'
import { uri } from '../../../api/uri'

export const initState = {
  scamInfo: {}
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async scamInfo ({ commit, dispatch }) {
    commit('flushErrors', null, { root: true })
    const url = uri.settings.messages.scamInfo
    // send message
    await this.$axios.$get(url)
      .then((resp) => {
        commit('setScamInfo', resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  }
  // , async scamProcess ({ commit, dispatch }) {
  //   commit('flushErrors', null, { root: true })
  //   const url = uri.settings.messages.scamProcess
  //   // send message
  //   await this.$axios.$post(url)
  //     .then((resp) => {
  //       dispatch('scamInfo')
  //     })
  //     .catch((e) => {
  //       commit('setError', {
  //         title: e.message,
  //         message: e.response && e.response.data && e.response.data.message
  //       }, { root: true })
  //     })
  // }
}
export const mutations = {
  updateField,
  setScamInfo (state, info) {
    state.scamInfo = info
  }
}
export const getters = {
  getField,
  scamInfo (state) {
    return state.scamInfo
  }
}
