import { getField, updateField } from 'vuex-map-fields'
import { uri } from '../../../api/uri'

export const initState = {
  profiles: [],
  searchList: []
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async index ({ commit }) {
    commit('flushErrors', null, { root: true })
    const url = uri.settings.hiddenProfiles.index
    // send message
    await this.$axios.$get(url)
      .then((resp) => {
        commit('setIndex', resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async search ({ commit }, query) {
    commit('flushErrors', null, { root: true })
    const url = uri.settings.hiddenProfiles.index
    // send message
    await this.$axios.$post(url, { q: query })
      .then((resp) => {
        commit('setSearchList', resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  flushSearch ({ commit }) {
    commit('flushSearchList')
  }
}
export const mutations = {
  updateField,
  setIndex (state, profiles) {
    state.profiles = profiles
  },
  setSearchList (state, profiles) {
    state.searchList = profiles
  },
  flushSearchList (state) {
    state.searchList = []
  }
}
export const getters = {
  getField,
  profiles (state) {
    return state.profiles
  },
  searchList (state) {
    return state.searchList
  }
}
