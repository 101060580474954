import { getField, updateField } from 'vuex-map-fields'
import { prepareUrl, uri } from '../../api/uri'
import { getFormInitState, GlobalFormStore } from '~/mixins/globalForm'

export const affiliateFormFields = {
  name: { type: String },
  slug: { type: String },
  payout: { type: String },
  postback_method: { type: String },
  postback_url: { type: String },
  usersBrought: { type: Number },
  filters: { type: Array }
}

const storageKey = 'AffiliateEditForm'

const { gfActions, gfMutations, gfGetters } = GlobalFormStore(storageKey, affiliateFormFields)

// initial fields
export const affiliateEditInitState = {
  forms: [],
  recognized: {
    total: 0,
    items: []
  },
  aggregated: 0,
  batch: null
}
export const state = () => {
  return {
    ...affiliateEditInitState
  }
}
export const actions = {
  ...gfActions,
  async recognize ({ commit }, id) {
    const url = prepareUrl(uri.affiliateRecognize, { id })
    // send test data
    await this.$axios.$post(url, {})
      .then((res) => {
        commit('setRecognized', res || 0)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        })
      })
  },
  async aggregateRecognized ({ commit }, id) {
    const url = prepareUrl(uri.affiliateAggregateRecognized, { id })
    // send test data
    await this.$axios.$post(url, {})
      .then((res) => {
        commit('setAggregated', res?.count || 0)
        commit('setBatch', res?.batch || null)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        })
      })
  },
  async cancelAggregateRecognized ({ commit }, id) {
    const url = prepareUrl(uri.affiliateCancelAggregateRecognized, { id })
    // send test data
    await this.$axios.$post(url, {})
      .then((res) => {
        commit('setBatch', null)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        })
      })
  },
  flushAggregated ({ commit }) {
    commit('flushAggregated')
  }
}
export const mutations = {
  updateField,
  ...gfMutations,
  fill (state, { id, data }) {
    const form = data && data.form
    const formData = data && data.formData
    const dirtyJson = localStorage.getItem(`${storageKey}.${id}`)
    const dirty = dirtyJson ? JSON.parse(dirtyJson) : {}
    const fetchedIdx = state.forms.findIndex(i => i.id === id)
    const item = fetchedIdx >= 0
      ? state.forms[fetchedIdx]
      : {
          id,
          type: data && data.type,
          dirty,
          oldForm: getFormInitState(affiliateFormFields),
          form: getFormInitState(affiliateFormFields),
          formData,
          key: 0,
          // Form components refresh keys
          keys: {},
          isFetched: false,
          editColumnName: '',
          editColumnState: false,
          editColumnCursor: -1
        }
    // Fill form data
    item.form.name = (form && `${form.name}`) || ''
    item.form.slug = (form && `${form.slug}`) || ''
    item.form.payout = (form && `${form.payout}`) || ''
    item.form.postback_method = (form && `${form.postback_method}`) || ''
    item.form.postback_url = (form && `${form.postback_url}`) || ''
    item.form.usersBrought = (form && `${form.usersBrought}`) || ''
    item.form.filters = JSON.stringify(form?.filters || [])

    // Copy form data
    Object.keys(item.form)
      .forEach((key) => {
        item.oldForm[key] = item.form[key]
      })
    // Update dirty data
    const dirtyFields = Object.keys(item.dirty)
    if (dirtyFields.length) {
      for (const dirtyField of dirtyFields) {
        item.form[dirtyField] = item.dirty[dirtyField]
      }
    }
    // Store or update data
    if (fetchedIdx >= 0) {
      state.forms[fetchedIdx] = item
    } else {
      state.forms.push(item)
    }
  },
  setRecognized (state, res) {
    state.recognized = {
      total: res?.total || 0,
      items: res?.items || []
    }
    state.batch = res?.batch || null
  },
  setAggregated (state, count) {
    state.aggregated = count
  },
  flushAggregated (state) {
    state.aggregated = 0
  },
  setBatch (state, batch) {
    state.batch = batch
  }
}
export const getters = {
  getField,
  ...gfGetters,
  recognized (state) {
    return state.recognized
  },
  aggregated (state) {
    return state.aggregated
  },
  batch (state) {
    return state.batch
  }
}
